import { Ref, forwardRef, useEffect, useRef } from "react";
import { Recipe } from "../../interfaces/Recipe";
import { fitTextInElement } from "./text-utils";

const BasicOneSidedRecipeCard = forwardRef((props: {recipe: Recipe}, ref: Ref<HTMLDivElement>) => {

    const {recipe} = props;

    const ingredientsElementRef = useRef<HTMLDivElement>(null);
    const instructionsElementRef = useRef<HTMLDivElement>(null);


    useEffect(() => {
        fitTextInElement([ingredientsElementRef.current, instructionsElementRef.current], {multiLine: true, maxFontSize: 14, minFontSize: 4});
      }, [recipe]);
      
    return ( 
        <div ref={ref}>
            <div className="p-4 w-[6in] h-[4in] border flex flex-col bg-white">
                <div className="flex flex-col h-[1in] gap-1">
                    <div className="text-lg font-bold flex justify-center">
                        {recipe.Name}
                    </div>
                    <div className="flex justify-evenly font-medium text-sm">
                        <div>
                            Cook Time: {recipe.CookTime}
                        </div>
                        <div>
                            Servings: {recipe.Yield}
                        </div>
                    </div>
                    <div className="border-t border-black mt-3"></div>
                </div>
                <div className="flex h-[3in]">
                    <div className="flex flex-col gap-1 basis-1/3">
                        <div className="font-semibold text-sm">Ingredients:</div>
                        <div className="flex flex-col leading-normal overflow-hidden h-[264px]" id="ingredients" ref={ingredientsElementRef}>
                            {recipe.Ingredients.map((x, i) => <div key={`ingredient-${i}`}>{x}</div>)}
                        </div>
                    </div>
                    <div className="border-l border-black mx-7 mt-8 h-[full]"></div>
                    <div className="flex flex-col gap-1 basis-2/3">
                        <div className="font-semibold text-sm">Directions:</div>
                        <div className="flex flex-col overflow-hidden h-[264px]" ref={instructionsElementRef}>
                            {recipe.Instructions.map((x, i) => <li key={`direction-${i}`}>{x}</li>)}
                        </div>
                    </div>
                </div>
            </div>  
        </div>
    );
});

export default BasicOneSidedRecipeCard;
