import Footer from "./footer/footer";
import Header from "./header/header";

function Layout( { children }: any) {
    return ( 
        <div className="px-5 sm:px-20">
            <Header></Header>
            <main>{children}</main>
            <Footer></Footer>
        </div>
     );
}

export default Layout;