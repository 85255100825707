import { Ref, forwardRef, useEffect, useRef } from "react";
import { Recipe } from "../../interfaces/Recipe";
import { fitTextInElement } from "./text-utils";

const TwoSidedFancy = forwardRef((props: {recipe: Recipe}, ref: Ref<HTMLDivElement>) => {

    const {recipe} = props;

    const firstColumnIngredientRef = useRef<HTMLDivElement>(null);
    const secondColumnIngredientRef = useRef<HTMLDivElement>(null);
    const instructionsElementRef = useRef<HTMLDivElement>(null);


    useEffect(() => {
        fitTextInElement([firstColumnIngredientRef.current, secondColumnIngredientRef.current, instructionsElementRef.current], {multiLine: true, maxFontSize: 14, minFontSize: 4});
      }, [recipe]);

      return ( 
        <div className="flex flex-col gap-3">      
            {recipe.Ingredients.length > 16 || recipe.Instructions.length > 12 ? 
                <div className="border-l-4 border-red-400 text-s bg-red-100 px-2">
                    Because your recipe has many ingredients and/or instructions, it might not fit on this card. Please review your recipe and consider selecting a different design.
                </div> : 
                <div className="border-l-4 border-gray-400 text-s bg-gray-100 px-2">
                    To print a two-sided recipe card, either use your printer’s double-sided print option or print one page at a time, arranging the paper to print on the back of the first page.
                </div> 
            }
            <div ref={ref}>
                <div className="flex flex-wrap gap-3 min-w-[6in]">
                    <div className="p-4 w-[6in] h-[4in] border flex flex-col">
                        <div className="flex flex-col h-[1in] gap-3">
                            <div className="flex">
                                <div className="mx-4 font-wind-song text-4xl">Recipe</div>
                                <div className="border-b border-gray-600 w-full mx-3 content-end text-center font-belleza">{recipe.Name}</div>
                            </div>
                            <div className="flex justify-between font-belleza whitespace-nowrap overflow-hidden">
                                <div className="flex">
                                    <div className="mr-3 text-nowrap italic">prep time</div>
                                    <div className="border-b border-gray-600 w-20 content-end text-center">{recipe.PrepTime}</div>
                                </div>
                                <div className="flex">
                                    <div className="mr-3 text-nowrap italic">cook time</div>
                                    <div className="border-b border-gray-600 w-20 content-end text-center">{recipe.CookTime}</div>
                                </div>
                                <div className="flex">
                                    <div className="mr-3 italic">serves</div>
                                    <div className="border-b border-gray-600 w-20 content-end text-center">{recipe.Yield}</div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-2 w-full font-belleza">
                            <div className="font-semibold text-sm text-center">INGREDIENTS</div>
                            <div>
                            <div className="flex leading-normal overflow-hidden h-[226px] gap-5" id="ingredients">
                                <div className="flex flex-col justify-around w-[261px]" ref={firstColumnIngredientRef}>
                                    {[...Array(8)].map((_, i) => <div className="border-b border-gray-600 h-[28px] overflow-hidden text-nowrap content-end" key={`ingredient-${i}`}>{recipe.Ingredients[i] ?? ""}</div>)}
                                </div>
                                <div className="flex flex-col justify-around w-[261px]" ref={secondColumnIngredientRef}>
                                    {[...Array(8)].map((_, i) => <div className="border-b border-gray-600  h-[28px] overflow-hidden text-nowrap content-end" key={`ingredient-${i}`}>{recipe.Ingredients[i+8] ?? ""}</div>)}
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>  
                    <div className="pagebreak"></div>
                    <div className="p-4 w-[6in] h-[4in] border flex flex-col page-two font-belleza">
                        <div className="flex flex-col gap-2 h-full">
                            <div className="font-semibold text-sm text-center">DIRECTIONS</div>
                            <div className="flex flex-col overflow-hidden h-[325px]" ref={instructionsElementRef}>
                                {recipe.Instructions.map((x, i) => <div className="border-b border-gray-600 content-end min-h-7	" key={`direction-${i}`}>{x}</div>)}
                            </div>
                        </div>
                    </div>  
                </div>
            </div>
        </div>
    );
});

export default TwoSidedFancy;
