export function fitTextInElement(elements: HTMLElement | Array<HTMLElement | null> | null, options?: FitTextSettings) : Array<number | null> {
    if (!elements) {
        return [];
    }

    if (!options) {
        options = {};
    }

    let settings = {
        multiLine: true,
        minFontSize: 6,
        maxFontSize: 80,
        widthOnly: false
    };

    Object.assign(settings, options);

    if (!Array.isArray(elements)) {
        elements = [elements];
    }

    const sizes = elements.map(x => processElement(x, settings));

    return sizes;
}
export function processElement(element: HTMLElement | null, settings: FitTextSettings) : number | null {
    if(!element) {
        return null;
    }

    element.setAttribute("textFitted", "true");

    const elementHtml = element.innerHTML;
    const elementWidth = innerWidth(element);
    const elementHeight = innerHeight(element);

    if (!elementWidth || (!settings.widthOnly && !elementHeight)) {
        if (!settings.widthOnly)
            throw new Error('Set a static height and width on the target element ' + element.outerHTML +
                ' before fitting text to elment');

        else
            throw new Error('Set a static width on the target element ' + element.outerHTML +
                ' before fitting text to elment!');
    }

    const hiddenSpan = document.createElement('span');
    hiddenSpan.className = 'fittingText';
    hiddenSpan.style['display'] = 'inline-block';
    hiddenSpan.innerHTML = elementHtml;
    hiddenSpan.hidden = true;
    element.appendChild(hiddenSpan);

    if (!settings.multiLine) {
        element.style.whiteSpace = 'nowrap';
    }

    let minFontSize = settings.minFontSize ?? 6;
    let maxFontSize = settings.maxFontSize ?? 80;
    let mid: number;

    let size = minFontSize;
    while (minFontSize <= maxFontSize) {
        mid = (maxFontSize + minFontSize) >> 1;
        element.style.fontSize = mid + 'px';
        var innerSpanBoundingClientRect = hiddenSpan.getBoundingClientRect();
        if (innerSpanBoundingClientRect.width <= elementWidth
            && (settings.widthOnly || innerSpanBoundingClientRect.height <= elementHeight)) {
            size = mid;
            minFontSize = mid + 1;
        } else {
            maxFontSize = mid - 1;
        }
    }

    hiddenSpan.remove();
    element.style.fontSize = size + 'px';

    return size;
}
export function innerWidth(element: HTMLElement) {
    const style = window.getComputedStyle(element, null);
    return element.getBoundingClientRect().width -
        parseInt(style.getPropertyValue('padding-left'), 10) -
        parseInt(style.getPropertyValue('padding-right'), 10);
}
export function innerHeight(element: HTMLElement) {
    const style = window.getComputedStyle(element, null);
    return element.getBoundingClientRect().height -
        parseInt(style.getPropertyValue('padding-top'), 10) -
        parseInt(style.getPropertyValue('padding-bottom'), 10);
}
export interface FitTextSettings {
    multiLine?: boolean;
    minFontSize?: number;
    maxFontSize?: number;
    widthOnly?: boolean;
}

