import { ChangeEvent, useEffect, useRef, useState } from 'react';
import './App.scss';
import { Recipe } from './interfaces/Recipe';
import BasicOneSidedRecipeCard from './components/recipe-card/basic-one-sided-recipe-card';
import SpinnerButton from './components/spinner-button/spinner-button';
import { useReactToPrint } from 'react-to-print';
import BasicTwoSidedRecipeCard from './components/recipe-card/basic-two-sided-recipe-card copy';
import TwoSidedFancy from './components/recipe-card/two-sided-fancy';

function App() {

  const [url, setUrl] = useState("");
  const [recipe, setRecipe] = useState<Recipe>({
    Name: "Sample Recipe",
    Yield: "4 Servings",
    PrepTime: "10 Minutes",
    CookTime: "20 Minutes",
    TotalTime: "30 Minutes",
    Ingredients: ["Ingredient 1"],
    Instructions: ["Instruction 1"]
  } as Recipe);
  const [loadingRecipe, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedRecipeCard, setSelectedRecipeCard] = useState("basic-one-sided");

  const recipeDivRef = useRef<HTMLDivElement>(null);
  const recipeToPrintRef = useRef<HTMLDivElement>(null);

  const urlPattern = /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
  const urlRegex = new RegExp(urlPattern);

  useEffect(() => {
    if(recipe.Name !== "Sample Recipe") {
      recipeDivRef.current?.scrollIntoView();
    }
  }, [recipe])
  

  async function handleGoClick(): Promise<void> {
    setLoading(true);
    try {
      if(url.match(urlRegex)) {
        const response = await fetch(`${process.env.REACT_APP_JUST_RECIPES_BACKEND_BASE_URL}/web?url=${url}`);
        console.log(recipe);
        if(response.ok) {
          const recipeText = await response.text();
          const recipe = JSON.parse(htmlDecode(recipeText)) as Recipe
          setRecipe(recipe);
          setErrorMessage("");
        }
        else {
          setErrorMessage("Sorry! We couldn’t retrieve the recipe from the provided URL. Our team is aware of the issue and is working diligently to resolve it. Thank you for your patience.");
        }
      }
      else {
        setErrorMessage("The URL you have provided is invalid");
      }
    } catch (error) {
      setErrorMessage('Sorry! An issue occurred, and we are currently unable to retrieve the recipe.');
    }
    setLoading(false);
  }

  const print = useReactToPrint({
    content: () => recipeToPrintRef.current,
  });

  function updateInputText(event: ChangeEvent<HTMLInputElement>): void {
    setUrl(event.target.value);
  }

  function htmlDecode(input: string): string {
    var doc = new DOMParser().parseFromString(input, "text/html");
    return doc.documentElement.textContent ?? "";
  }

  function handleRecipeCardSelection(event: ChangeEvent<HTMLInputElement>): void {
    setSelectedRecipeCard(event.target.value);
  }

  return (
    <div className="flex flex-col gap-16">
      <div className='flex gap-5'>
        <div className='flex flex-col lg:basis-1/2'>
          <h1 className='font-semibold text-3xl sm:text-6xl mb-9 leading-tight'>
            Easily create printable recipe cards for recipes from the web!
          </h1>
          <div className='text-xl sm:text-2xl text-gray-600'>
            Enter a recipe URL in the box below and click “Go”
          </div>
          {errorMessage !== "" && <div className='mt-5 text-red-500'>{errorMessage}</div>}
          <input type='text' onChange={updateInputText} placeholder='https://www.recipe-url.com/recipe'  
          className='w-full rounded-lg text-sm h-10 px-2 box-border shadow-text-input my-5' />
          <SpinnerButton loading={loadingRecipe} onClick={handleGoClick}>Go</SpinnerButton>
          <div className='text-center bg-gray-100 min-h-20 content-center p-5 mt-8'>
            <p>JustRecipes makes it easy to turn any recipe from the web into a printable recipe card! Simply copy the URL from your favorite recipe webpage, paste it into the text box above, and click 'Go'. It's that simple!</p>
          </div>
        </div>
        <div className='hidden lg:flex justify-center basis-1/2 p-5 shrink'>
          <img src='/images/recipe-box.jpg' className='max-w-full m-auto bg-gray-50 p-5' alt="A recipe card box"/>
        </div>
      </div>
      <div ref={recipeDivRef} className='flex flex-col gap-10 p-5'>
        <div className='flex'>
          <div className='text-3xl sm:text-4xl text-gray-800'>
            {recipe.Name !== "Sample Recipe" ? "Print your recipe" : "Sample"}
          </div> 
        </div>
        <div className='flex flex-col gap-3'>
          <div className='flex gap-3'>
            <div>
              <label>
                <input className='mr-1' type='radio' value="basic-one-sided" checked={selectedRecipeCard === "basic-one-sided"} onChange={handleRecipeCardSelection} />
                One Sided
              </label>
            </div>
            <div>
              <label>
                <input className='mr-1' type='radio' value="basic-two-sided" checked={selectedRecipeCard === "basic-two-sided"} onChange={handleRecipeCardSelection} />
                Two Sided
              </label>
            </div>
            <div>
              <label>
                <input className='mr-1' type='radio' value="fancy-two-sided" checked={selectedRecipeCard === "fancy-two-sided"} onChange={handleRecipeCardSelection} />
                Elegant Two Sided
              </label>
            </div>
          </div>
          {selectedRecipeCard === "basic-one-sided" && <BasicOneSidedRecipeCard ref={recipeToPrintRef} recipe={recipe} />}
          {selectedRecipeCard === "basic-two-sided" && <BasicTwoSidedRecipeCard ref={recipeToPrintRef} recipe={recipe} />}
          {selectedRecipeCard === "fancy-two-sided" && <TwoSidedFancy ref={recipeToPrintRef} recipe={recipe} />}
        </div>
        {recipe.Name !== "Sample Recipe" && 
          <button onClick={print} className='w-44 font-semibold h-12 text-white bg-black border-solid border-2 border-black'>Print</button>}
      </div>
    </div>
  );
}

export default App;

