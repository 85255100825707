function SpinnerButton({ loading, onClick, children } : React.PropsWithChildren<SpinnerButtonProps>) {

    return ( 
        <button className={`w-44 h-12 font-semibold border-solid border-2 border-black flex justify-center items-center ${
            loading ? '' : 'cursor-pointer'}`} onClick={onClick} disabled={loading}>
                {loading ? (
                    <img
                        src="/images/loadingSpinner.svg"
                        alt="Loading spinner"
                        className="h-12 w-auto"
                    />
                ) : (
                    children
                )}
        </button> );
}

interface SpinnerButtonProps {
    loading: boolean, 
    onClick: () => void
}

export default SpinnerButton;