function Footer() {
    return (
        <div className='mt-10'>
            <div className='h-3.5 bg-gray-100'></div>
            <div className='h-36 flex flex-col justify-between py-5'>
                <div className='flex justify-between gap-3'>
                    <div>
                        <div className="text-base font-semibold">JustRecipes</div>
                        <div className='text-sm text-gray-500'>Easily print recipe cards for your favorite recipes on the web</div>
                    </div>
                    <div className='footer-links'>
                        {/* <div className='flex flex-col'>
                            <span className='text-base font-medium'>Support</span>
                            <a className='text-base text-gray-500'>Feedback</a>
                        </div> */}
                    </div>
                </div>
                <div className='text-sm text-gray-700 flex justify-center'>
                    Copyright ©2024 JustSoft, LLC
                </div>
            </div>
        </div>
    );
}

export default Footer;